import Vue from 'vue';
import Vuex from 'vuex';
// import { WhiteboardConfig } from '../config/basic-info-config';
// import Config from '../config';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isSignalReady: false,
    rightOfUse: false, // 白板使用权限
    medalNum: 0,
    sumMessageLength: 0,
    nowMessageLength: 0,
    current: {
      toolType: 0,
      fileInfo: {},
    },

    boardSetting: {

    },

    rightBarShow: false,
  },

  mutations: {
    setRightOfUse(state, payload) {
      state.rightOfUse = payload;
    },
    setMedalNum(state, payload) {
      state.medalNum = payload;
    },
    setSumMessageLength(state, payload) {
      state.sumMessageLength = payload;
    },
    setNowMessageLength(state, payload) {
      state.nowMessageLength = payload;
    },
    setSignalReady(state, payload) {
      state.isSignalReady = payload;
    },

    setRightBarShow(state, payload) {
      state.rightBarShow = payload;
    },

    setCurrentFile(state, payload) {
      state.current.fileInfo = payload;
    },

    updateBoardSetting(state, payload) {
      const t = state.current.boardSetting;
      state.current.boardSetting = {
        ...t,
        ...payload,
      };
    },
  },

  actions: {

    setSignalReady({ commit }, payload) {
      commit('setSignalReady', payload);
    },

    setRightBarShow({ commit }, payload) {
      commit('setRightBarShow', payload);
    },

    setCurrentFile({ commit }, payload) {
      commit('setCurrentFile', payload);
    },

    updateBoardSetting({ commit }, payload) {
      commit('updateBoardSetting', payload);
    },
  },
});
