import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { createPinia, PiniaVuePlugin } from 'pinia';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import vuetify from './plugins/vuetify';

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.use(ElementUI);

Vue.config.productionTip = false;
Vue.prototype.$store = store;
Vue.prototype.$EventBus = new Vue();

new Vue({
  pinia,
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app');
