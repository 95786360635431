import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
  },
  {
    path: '/buildRoom',
    name: 'buildRoom',
    component: () => import('../views/buildRoom.vue'),
  },
  {
    path: '/room',
    name: 'room',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/room.vue'),
  },
];

const router = new VueRouter({
  routes,
});


router.beforeEach((to, from, next) => {
  if (to.path == '/login' || to.path == '/') {
    next();
  } else {
    if (sessionStorage.loginInfo) {
      next();
    } else {
      next('/');
    }
  }
});
export default router;
